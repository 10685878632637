<template>
  <div @keydown.enter.prevent="handleSave">
    <v-form ref="NewEmployeeForm" @submit.prevent="handleSave">
      <v-container fluid>
        <v-row class="pb-0 mb-0">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="name"
              :disabled="isLoadingData"
              :label="$trans('name')"
              :rules="[rules.required, rules.maxChars(50)]"
              hide-details="auto"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="surname"
              :disabled="isLoadingData"
              :label="$trans('surname')"
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(50)]"
              outlined
            />
          </v-col>
          <v-col cols="12" class="mt-0 pt-0">
            <v-text-field
              v-model="email"
              :disabled="isLoadingData"
              :label="$trans('email')"
              :rules="email ? [rules.email, rules.maxChars(60)] : []"
              validate-on-blur
              hide-details="auto"
              outlined
            />
          </v-col>
        </v-row>
        <v-row v-if="email">
          <v-col class="mt-0 pt-0 mb-0 pb-0">
            <v-checkbox
              v-model="sendPassword"
              :disabled="isLoadingData"
              :label="$trans('send_reset_password')"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-0 pt-0">
            <v-checkbox
              v-model="isPublic"
              :disabled="isLoadingData"
              :label="$trans('employee_public_checkbox')"
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxChars,
  required,
  email,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "NewEmployeeDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoadingData: false,
      name: null,
      surname: null,
      email: null,
      sendPassword: true,
      isPublic: true,
      rules: {
        required,
        maxChars,
        email,
      },
    };
  },
  methods: {
    ...mapActions({
      addEmployee: "employee/add",
    }),
    handleSave() {
      if (this.$refs.NewEmployeeForm.validate()) {
        this.isLoadingData = true;
        this.$emit("ctaButtonLoading", true);

        this.addEmployee({
          name: this.name,
          surname: this.surname,
          email: this.email,
          send_reset_password: this.sendPassword,
          is_public: this.isPublic,
        })
          .then((data) => {
            this.clearCache("employee-list-v2");
            this.isLoadingData = false;
            this.$emit("ctaButtonLoading", false);
            this.$emit("close", { reloadEmployee: true, employeeId: data.id });
          })
          .catch((error) => {
            this.isLoadingData = false;
            this.$emit("ctaButtonLoading", false);

            if (
              this.$helpers.emailIsTakenError(error) ||
              error.response.status === 409
            ) {
              errorNotification("email_is_taken", error, false);
            } else {
              errorNotification("add_employee_fail", error);
            }
          });
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
  },
};
</script>
